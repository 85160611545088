import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/recolte_logo.png';
import call from '../assets/call.png';
import MenuButton from './MenuButton';

class Menu extends Component {
    state = { clicked: false };

    handleClick = () => {
        this.setState({clicked:
        !this.state.clicked});
        if (this.state.clicked == false){
            document.getElementById('mobile-menu').style.display = "flex";
        } else {
            document.getElementById('mobile-menu').style.display = "none";
        }
    }

    render() {
    
        return (
            <ul className='menu'>
                <Link to='/?targetId=home'>
                    <div className='logo-container'>
                        <img className='logo_menu' src={logo} alt='Logo' />
                    </div>
                </Link>
                <div className='desktopNav'>
                    <div className='info-block2' style={{ marginRight: "50px" }}>
                        <button
                            onClick={() => this.props.navigate('/?targetId=kontakt')}
                            className='button-menu'
                        >
                            Napisz do nas
                        </button>
                    </div>
                    <div className='info-block2'>
                        <div style={{ position: "relative", top: "50%", transform: "translate(0,-50%)" }}>
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                <img style={{ width: "13px", height: "13px", marginRight: "20px", position: "relative", top: "5px" }} src={call} alt='Call' />
                                <p>500 825 787</p>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <img style={{ width: "13px", height: "13px", marginRight: "20px", position: "relative", top: "5px" }} src={call} alt='Call' />
                                <p>793 003 901</p>
                            </div>
                        </div>
                    </div>
                    <li><Link className='link_menu_right' to='/blog'>Blog</Link></li>
                    <li><MenuButton nameClass="link_menu" name="Kontakt" targetId={'kontakt'} /></li>
                    <li><MenuButton nameClass="link_menu" name="FAQ" targetId={'faq'} /></li>
                    <li><Link className="link_menu" to='/kalkulator'>Wycena</Link></li>
                    <li><MenuButton nameClass="link_menu" name="Oferta" targetId={'oferta'} /></li>
                    <li><MenuButton nameClass="link_menu" name="O nas" targetId={'o-nas'} /></li>
                </div>
                <div className="mobileNav" onClick={this.handleClick}>
                <li><i className={
                        this.state.clicked ? "fa-time" : "fa-bars"
                    } ></i></li>
                    <div className='mobile-menu' id='mobile-menu'>
                        <li style={{zIndex:"1"}}><MenuButton nameClass="link_menu" name="O nas" targetId={'o-nas'} idName={"link-menu-mobile"}/></li>
                        <li  style={{zIndex:"1"}}><MenuButton nameClass="link_menu" name="Oferta" targetId={'oferta'} idName={"link-menu-mobile"}/></li>
                        <li  style={{zIndex:"1"}}><a className="link_menu" id='link-menu-mobile' href="/kalkulator">Wycena</a></li>
                        <li  style={{zIndex:"1"}}><MenuButton nameClass="link_menu" name="FAQ" targetId={'faq'} idName={"link-menu-mobile"}/></li>
                        <li><MenuButton nameClass="link_menu" name="Kontakt" targetId={'kontakt'} idName={"link-menu-mobile"}/></li>
                        <li><a className='link_menu_right' id='link-menu-mobile' href='/blog'>Blog</a></li>
                        <div className='image-mobile-menu'></div>
                    </div>
                </div>
            </ul>
        );
    }
}

// Higher-order component to inject the navigate function
const MenuWithNavigate = (props) => {
    const navigate = useNavigate();
    return <Menu {...props} navigate={navigate} />;
};

export default MenuWithNavigate;