import React, { useEffect, useState } from "react";
import { motion, useAnimationControls } from "framer-motion";

const Wycena = () => {

    const [meters, setMeters] = useState("");
    const [error_meters, setErrorMeters] = useState("")

    const [phone, setPhone] = useState("");
    const [error_phone, setErrorPhone] = useState("");

    const [email, setEmail] = useState("");
    const [error_email, setErrorEmail] = useState("");

    const [city, setCity] = useState("");
    const [error_city, setErrorCity] = useState("");

    const [postcode, setPostcode] = useState("");
    const [error_postcode, setErrorPostcode] = useState("");

    const [message, setMessage] = useState("");
    const [error_message, setErrorMessage] = useState("");

    const [clickPositions, setClickPositions] = useState({});

    const [thickness, setThicness] = useState(15);
    const [error_thicness, setErrorThickness] = useState("");

    const [productPhotos, setProductPhotos] = useState([]);
    const [skade, setSkade] = useState([])
    const [error_skade, setErrorSkade] = useState([]);

    useEffect(()=>{
        console.log(skade)
    }, [skade])

    const [loading, setLoading] = useState(false)
    const controls = useAnimationControls();

    const nesteSide = (e) => {
        e.preventDefault();
        controls.start({
            right: "0%"
        });
    };

    const nesteSide1 = (e) => {
        e.preventDefault();

        if (meters == ""){
            setErrorMeters("Wpisz szacowany metraż")
        } 
    
        if (meters != "") {
            controls.start({
                right: "20%"
            });
            setErrorMeters("")
            const elements = document.querySelectorAll('.list_skade'); // Select all elements with class 'myDiv'
            elements.forEach(element => {
                element.style.display = 'block'; // Set display to 'none' for each element
            });
        }

    };

    const nesteSide2 = (e) => {
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       
        if (email == ""){
            setErrorEmail("Wpisz adres e-mail")
        } else if (!emailRegex.test(email)) {
            setErrorEmail("Wpisz poprawny adres e-mail")
        } 
        if (phone == ""){
            setErrorPhone("Wpisz numer telefonu")
        }
        if (city == ""){
            setErrorCity("Wpisz nazwe miejscowości")
        }
        if (postcode == ""){
            setErrorPostcode("Wipsz kod pocztowy miejscowości")
        } 

        if(email != "" && phone != "" && city != "" && postcode != ""){
            const elements = document.querySelectorAll('.list_skade'); // Select all elements with class 'myDiv'
            elements.forEach(element => {
                element.style.display = 'none'; // Set display to 'none' for each element
            });
            controls.start({
                right: "40%"
            });

        }
           
    };

    const nesteSide3 = (e) => {
        e.preventDefault();
            controls.start({
                right: "60%"
            });
           
    };


    const changeValue = (field, value) => {
        console.log(field, value)
        switch (field) {
            case "meters":
                setMeters(value);
                break;
            case "phone":
                setPhone(value);
                break;
            case "email":
                setEmail(value);
                break;
            case "city":
                setCity(value);
            case "postcode": 
                setPostcode(value);
            case "message":
                setMessage(value);
                break;
            case "thickness":
                    setThicness(value)
                    break;
            default:
                break;
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission
       
          setLoading(true)
        
        try {
            
            const data = {
                meters: meters,
                thickness: thickness,
                phone: phone,
                email: email,
                message: message,
                city: city,
                postcode: postcode
            };

            console.log(data)

    
            console.log("Data", JSON.stringify(data));
    
            // Now submit the form with the updated data object
            const req = await fetch('https://admin.recolte.pl/api/calculator', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
    
            if (req.ok) {
                console.log('Form submitted successfully');

                window.location.href = "/wycena";
            } else {
                console.error('Error:', req.status, req.statusText);
            }
    
        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };

    return (
        <form onSubmit={onSubmit} noValidate>
            {!loading?
                <div className="transparent-background">
                <motion.div animate={controls} className="inside-background">
                    <div style={{display:"flex", flexDirection:"column"}} className="sliding-column">
                    <h1 style={{fontSize:"35px"}} className="form-h1">Informacje dotyczące projektu</h1>
                        <h2 style={{width:"100%", textAlign:"left", maxWidth:"350px", margin:"0 auto"}} className="form-h2">Podaj szacowaną powierzchnię do ocieplenia  ( <span className="msquared">&#13217;</span> )</h2>
                        
                        <input type="number" style={{width:"100%", color:"white", backgroundColor:"#1D1D1D", maxWidth:"350px", margin:"0 auto", height:"56px", fontSize:"25px", padding:"10px", marginTop:"20px"}} onChange={ (e) => changeValue("meters", e.target.value)}></input>
                       
                      <p>{error_meters}</p>

                      <h2 style={{width:"100%", textAlign:"left", maxWidth:"350px", margin:"0 auto", marginTop:"10px"}} className="form-h1">Szacowana grubość ocieplenia</h2>

                      <select name="grubosc" id="grubosc" onChange={(e) => changeValue("thickness", e.target.value)}
                            style={{height:"50px", margin:"auto", marginTop:"20px", fontSize:"20px", width:"100%", maxWidth:"350px", backgroundColor:"#1D1D1D", border:"none", color:"white", padding:"10px"}}
                            >
                                <option value={15}>15cm</option>
                                <option value={20}>20cm</option>
                                <option value={25}>25cm</option>
                                <option value={30}>30cm</option>
                        </select>
                    
                        <p>{error_thicness}</p>

                        <button className="black-button" style={{backgroundColor:"#1D1D1D", marginTop: "25px", width:"150px", marginTop:"20px", height:"45px", fontSize:"25px", color:"white", margin:"auto" }} onClick={nesteSide1}>
                            Dalej
                        </button>
                    </div>
                    <div className="sliding-column">
                    <h1 className="form-h1">Informacje kontaktowe</h1>

                        <h1 className="form-h1">Adres email</h1>
                        
                        <input style={{width:"100%", color:"white", backgroundColor:"#1D1D1D",  maxWidth:"450px", margin:"auto", height:"56px", fontSize:"20px", padding:"10px", marginTop:"20px"}} onChange={ (e) => changeValue("email", e.target.value)}></input>
                       
                      <p>{error_email}</p>

                      <h1 className="form-h1">Numer telefonu</h1>
                        
                        <input style={{width:"100%", color:"white", backgroundColor:"#1D1D1D",  maxWidth:"450px", margin:"auto", height:"56px", fontSize:"25px", padding:"10px", marginTop:"20px"}}  onChange={ (e) => changeValue("phone", e.target.value)}></input>
                       
                      <p>{error_phone}</p>
                
                      <h1 className="form-h1">Miasto</h1>
                        
                        <input style={{width:"100%",  color:"white",backgroundColor:"#1D1D1D",  maxWidth:"450px", margin:"auto", height:"56px", fontSize:"25px", padding:"10px", marginTop:"20px"}}  onChange={ (e) => changeValue("city", e.target.value)}></input>
                       
                      <p>{error_city}</p>

                      <h1 className="form-h1">Kod pocztowy</h1>
                        
                        <input style={{width:"100%",  color:"white", backgroundColor:"#1D1D1D",  maxWidth:"450px", margin:"auto", marginBottom:"30px", height:"56px", fontSize:"25px", padding:"10px", marginTop:"20px"}}  onChange={ (e) => changeValue("postcode", e.target.value)}></input>
                       
                      <p>{error_postcode}</p>

                        <div className="buttons-form">
                            <button className="black-button" style={{ marginTop: "25px", width:"150px", marginTop:"20px", height:"45px", fontSize:"25px", margin:"auto", color:"white", marginRight:"20px" ,backgroundColor:"#737373",}} onClick={nesteSide}>
                                Wstecz
                            </button>
                            <button className="black-button" style={{ marginTop: "25px", width:"150px", marginTop:"20px", height:"45px", fontSize:"25px", margin:"auto", color:"white", backgroundColor:"#737373", }} onClick={nesteSide2}>
                                Dalej
                            </button>
                        </div>
                    </div>
                    <div className="sliding-column">
                        <h1 className="form-h1">Dodatkowe informacje (Opcjonalne)</h1>
                        <textarea style={{width:"100%", color:"white", backgroundColor:"#1D1D1D",  maxWidth:"600px", margin:"auto", height:"250px", fontSize:"20px", padding:"10px", marginTop:"20px"}} onChange={ (e) => changeValue("message", e.target.value)}></textarea>
   
                        <div className="">
                            <button className="black-button" id="tilbake-button" style={{ marginTop: "25px", width:"150px", marginTop:"20px", height:"45px", fontSize:"25px", margin:"auto", color:"white", marginRight:"20px",backgroundColor:"#737373",}} onClick={nesteSide1}>
                                Wstecz
                            </button>
                            <button className="black-button" style={{ marginTop: "25px", width:"150px", marginTop:"20px", height:"45px", fontSize:"25px", margin:"auto",color:"white" ,backgroundColor:"#737373", }} >
                                Wyślij
                            </button>
                        </div>
                    </div>
                    <div className="sliding-column">
                    <h1 className="form-h1">Tlf. nummer</h1>        
                    </div>

                </motion.div>
            </div>:
                <div className="transparent-background">
                    <div class="loader"></div>
                </div>
                
            }
            
        </form>
    );
};

const styles = {
    closeButton: {
        position: "relative",
        background: "none",
        border: "none",
        fontSize: "24px",
        cursor: "pointer",
        color: "#000",
        width:"fit-content",
        left: "5px"
    },
};


export default Wycena;
