import Footer from "../components/Footer";
import Menu from "../components/Menu";
import MainArticle from "../components/MainArticle";
import ArticleComponent from "../components/ArticleComponent";
import AxiosContext from "../AxiosContext";
import { useQuery } from '@tanstack/react-query'
import { useContext, useEffect } from "react";

export default function BlogPage(){
    
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures it runs only on mount


    const axios = useContext(AxiosContext)

    const fetchBlog = async () => {
        const [ articles ] = await Promise.all([
            axios.get('/api/post/66ade18a54492de91306ba2c?locale=undefined&draft=false&depth=1')
        ])
        return articles.data
    }

    const { isLoading, data, error } = useQuery({
        queryKey: ['blog'],
        queryFn: fetchBlog
      });

     

    return (
        <div>
            <Menu/>
            {!isLoading?
            <section>
                <div style={{height:"200px"}}></div>
            <h1 style={{color:"black", margin:"auto", width:"fit-content", fontSize:"70px"}}>Blog</h1>
            <p className="blog-global-text" style={{color:"black", width:"fit-content", maxWidth:"540px", fontSize:"20px"}}>
                Ciekawi Cię temat ocieplania pianą? Chcesz poszerzyć swoją wiedzę? Zapraszamy do zapoznania się 
                z naszym blogiem!
            </p>
            {/*<section style={{maxWidth:"700px", margin:"auto"}}>
                <input 
                className="blog-global-input"
                style={{
                    backgroundColor:"#E7E7E7", 
                    color:"#9D9D9D" , 
                    maxWidth:"700px", 
                    width:"100%", 
                    position:"relative",  
                    left:"50%", 
                    transform:"translate(-50%)", 
                    marginTop:"50px", 
                    border:"none",
                    fontSize:"22px"
                }}
                
                placeholder="Wpisz szukaną frazę"
            ></input>
            <div className="lopka"></div>
            </section> */}
            <a href={`/posts/${data.posts[0].id}`}>
            <MainArticle articles={data}/>
            </a>
            <section style={{maxWidth:"1222px", margin:"auto", marginTop:"100px", marginBottom:"100px"}}>
                <h3 className="wpisy" style={{color:"#1D1D1D", fontSize:"30px", marginBottom:"40px"}}>Wszystkie wpisy:</h3> 
                <div className="article-container">
                    {data.posts.map((post) => (
                        <a href={`/posts/${post.id}`} style={{cursor:"pointer"}}>
                        <ArticleComponent
                            height="219px"
                            image={`https://admin.recolte.pl${post.upload.url}`}
                            fontSizeH2="30px"
                            post={post}
                        />  
                        </a>
                    ))}  
                </div>  
            </section>
            </section>:
            <section></section>}
            
            <Footer/>
        </div>
    );
}