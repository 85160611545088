import { useNavigate } from 'react-router-dom';

export default function MainSection () {

  const navigate = useNavigate();

    return (
        <div className="main-section-background">
          <h1 className="main-h1">Ocieplimy Twoje <br></br> otoczenie!</h1>
          <p className="main-p">Wykonamy dla Ciebie ocieplenie pianą <br></br> poliuretanową oraz hydroizolację natryskową.</p>
          <button onClick={() => navigate('/kalkulator')} className="cta-button" style={{marginBottom:"20px"}}>Wyceń swój projekt online</button>
        </div>
    );
}