import { useEffect } from "react";
import Wycena from "../components/compositions/wycena";
import Footer from "../components/Footer";
import MenuWithNavigate from "../components/Menu";

export default function WycenaPage() {
    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures it runs only on mount

    return (
        <section style={{ overflowX: "hidden", width: "100%" }}>
            <MenuWithNavigate />
            <div className="pristilbud-background">
                <Wycena />
            </div>
            <Footer />
        </section>
    );
}
