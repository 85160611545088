import Footer from "../components/Footer";
import Menu from "../components/Menu";
import { useEffect } from "react";

export default function ThankYouPage() {

    useEffect(() => {
        window.gtag('config', 'AW-16685760834');
        window.gtag('event', 'conversion', { 'send_to': 'AW-16685760834/RpaYCIL5os4ZEMKCspQ-' });
    }, []);

    return (
        <div>
    <Menu />
    <div style={{ height: "130px" }}></div>
    <h1 style={{
        color: "black",
        textAlign: "center",
        margin: "20px auto",
        marginTop: "100px",
        fontSize: "clamp(1.5rem, 4vw, 2.5rem)" // Responsive font size
    }}>
        Zapytanie zostało pomyślnie wysłane!
    </h1>
    <h2 style={{
        color: "black",
        textAlign: "center",
        margin: "20px auto",
        marginTop: "20px",
        fontSize: "clamp(1.2rem, 3vw, 2rem)" // Responsive font size
    }}>
        Skontaktujemy się z toba w przeciągu 24 godzin.
    </h2>
    <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px"
    }}>
        <a href="https://recolte.pl/blog">
            <button className="sendButton" id="thank-you-buttons" style={{
                width:"fit-content",
                margin: "10px",
                padding: "0px 20px",
                fontSize: "clamp(1rem, 2.5vw, 1.5rem)" // Responsive button size
            }}>
                Odwiedź nasz blog
            </button>
        </a>
        <a href="https://recolte.pl/">
            <button className="sendButton" id="thank-you-buttons" style={{
                width:"fit-content",
                margin: "10px",
                padding: "0px 20px",
                fontSize: "clamp(1rem, 2.5vw, 1.5rem)" // Responsive button size
            }}>
                Powrót do strony głównej
            </button>
        </a>
    </div>
    <Footer />
</div>

    );
    
}